<template>
  <div class="body_section">
    <profileHeader
      :showFilter="false"
      @changedGlobalclient="changedGlobalclient"
    />
    <div class="body_content">
      <div class="main_content">
        <div class="subheader">
          <div>
            <input
              v-model="serch_title"
              @keyup="serchTitle()"
              class="search"
              type="text"
              placeholder="Search by Subject"
            />
          </div>
          <div class="d-flex">
            <notesFilters
              @applyFilter="applyFilters"
              @clearFilter="clearFilters"
              ref="notesFilter"
            ></notesFilters>
            <router-link to="/add-note" class="primary_btn"
              >ADD NOTE</router-link
            >
          </div>
        </div>
        <div class="body_cnt">
          <div class="notes_section">
            <div class="notes_header">
              <ul>
                <li>
                  <a
                    @click="onSelect()"
                    :class="{ active: selectedNumber == 0 }"
                    href="#"
                    >All</a
                  >
                </li>
                <!--<li><a href="#">internal</a></li>
                        <li><a href="#">Clients</a></li>-->
                <li>
                  <a
                    :class="{ active: selectedNumber == 1 }"
                    @click="onSelect(1)"
                    href="#"
                    >Confidential</a
                  >
                </li>
                <li>
                  <a
                    :class="{ active: selectedNumber == 2 }"
                    @click="onSelect(2)"
                    href="#"
                    >Public</a
                  >
                </li>
                <li v-if="userdata.roleId != 5">
                  <a
                    :class="{ active: selectedNumber == 3 }"
                    @click="onSelect(3)"
                    href="#"
                    >Custom</a
                  >
                </li>
              </ul>
            </div>
            <div class="notes_cnt">
              <template v-for="(note, index) in list">
                <div :key="index" class="note_list">
                  <div class="note_details">
                    <div class="note_left">
                      <h4>{{ checkProperty(note, "title") }}</h4>
                      <p>
                        {{ note.description ? note.description : "" }}
                      </p>
                      <ul class="uploaded-list">
                        <template v-for="(other, indx) in note.document">
                          <li :key="indx" @click="download_file(other)">
                            {{ other.name
                            }}<img src="@/assets/images/download_icon.svg" />
                          </li>
                        </template>
                      </ul>
                    </div>
                    <div class="note_right">
                      <label
                        ><span>Created by</span>
                        {{ note.createdByDetails.name }}
                        {{ note.createdOn | formatDate }}</label
                      >
                      <button>
                        <span
                          ><img
                            v-if="note.accessLevelId == 1"
                            src="@/assets/images/lock_icon.svg"/></span
                        >{{ checkProperty(note, "accessLevelDetails", "name") }}
                      </button>
                    </div>
                  </div>
                  <div
                    class="clients_list"
                    v-if="
                      note.taggedToDetails && note.taggedToDetails.length > 0
                    "
                  >
                    <!--<div v-if="note.taggedToDetails && note.taggedToDetails.length > 0" class="td_chips">
                                        <template v-for="(item1, ind) in note.taggedToDetails">
                                            <span v-if="ind < 4" :key="ind">{{ item1.name }} </span>
                                        </template>
                                        <div class="td_hidden_chips">
                                            <template v-if="note.taggedToDetails.length > 4">
                                                <span id="popover-target-1a">+{{ note.taggedToDetails.length - 4 }} </span>
                                            </template>
                                            <b-popover v-if="note.taggedToDetails.length > 4" target="popover-target-1a" triggers="hover" placement="bottom">
                                                <div class="td_hidden_chips_list">
                                                    <ul>
                                                        <template v-for="(item2, ind) in note.taggedToDetails">
                                                            <li v-if="ind >= 4" :key="ind">{{ item2.name }}</li>
                                                        </template>
                                                    </ul>
                                                </div>
                                            </b-popover>
                                        </div>
                                    </div>-->

                    <ul>
                      <li
                        v-for="(taggedUser, index) in note.taggedToDetails"
                        :key="index"
                      >
                        <div class="client">
                          <figure>
                            <img
                              :src="taggedUser.logo"
                              @error="
                                getProfilePhoto($event, taggedUser.gender)
                              "
                            />
                          </figure>
                          <div>
                            <label>{{ taggedUser.name }} </label>
                            <span>{{ taggedUser.categoryName }}</span>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-if="showLoading">
                <span class="no_data list_loader"
                  >Loading<img src="@/assets/images/loader.gif"
                /></span>
              </template>
              <template v-if="list.length <= 0 && loaded && !showLoading">
                <span class="no_data">No Data!</span>
              </template>
            </div>

            <div class="pagination_wrap padlr0">
              <div v-show="list.length > 0">
                <div class="pagination_content">
                  <h6>Show Records</h6>
                  <multiselect
                    :multiple="false"
                    @input="changePerPage()"
                    placeholder="Page"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :preserve-search="true"
                    v-model="perPage"
                    :options="perPeges"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} selected</span
                      >
                      <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                      ></span>
                    </template>
                  </multiselect>
                </div>
              </div>

              <b-pagination
                v-if="list.length > 0"
                v-model="page"
                :total-rows="totalPages"
                :per-page="perPage"
                pills
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect-inv";
import notesFilters from "@/views/filters/notesFilters.vue";

import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";

export default {
  name: "notes",
  methods: {
    changedGlobalclient(cl) {
      this.log(cl);
      //this.$refs.rout_view.serchTitle(cl);
    },
    onSelect(number) {
      if (number) {
        this.selectedNumber = number;
        this.filterData.accessLevelIds = [];
        this.filterData.accessLevelIds.push(number);
        this.getList();
      } else {
        this.selectedNumber = 0;
        this.filterData.accessLevelIds = [];
        this.getList();
      }
      try {
        this.$refs.notesFilter.clearFilter(true);
      } catch (err) {
        this.log(err);
      }
    },
    getList() {
      this.loaded = false;
      let postData = {
        filters: this.filterData,
        /*filters: {
          title: this.serch_title,
          roleIds: this.filterData.roleIds,
          statusIds: this.filterData.statusIds,
          createdOnRange: this.filterData.createdOnRange
        },
        */
        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };
      if (
        this.filterData.taggedToIds &&
        this.filterData.taggedToIds.length > 0
      ) {
        postData.filters.accessLevelIds.push(3);
      }
      postData["filters"]["title"] = this.serch_title;

      this.list = [];
      this.$store
        .dispatch("getNotesList", postData)
        .then((response) => {
          this.loaded = true;
          this.list = response.list;
          this.totalPages = response.totalCount;
        })
        .catch((err) => {
          this.log(err);
        });
    },
    changePerPage() {
      this.page = 1;
      this.getList();
    },
    applyFilters(filterData) {
      //this.filterData = { "title": "","categoryIds": [], "statusIds": [], "taggedToIds": [], "createdByIds": [],"createdOnRange": []},

      this.filterData.accessLevelIds = filterData.accessLevelIds;
      this.filterData.categoryIds = filterData.categoryIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.taggedToIds = filterData.taggedToIds;
      this.filterData.createdByIds = filterData.createdByIds;
      this.filterData.createdOnRange = filterData.createdOnRange;

      this.getList();
    },
    clearFilters() {
      this.serch_title = "";
      (this.filterData = {
        title: "",
        accessLevelIds: [],
        categoryIds: [],
        statusIds: [],
        taggedToIds: [],
        createdByIds: [],
        createdOnRange: [],
      }),
        this.getList();
    },

    serchTitle() {
      this.page = 1;
      this.getList();
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "createdOn", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;

        this.getList();
      }
    },
  },
  data() {
    return {
      loaded: false,
      selectedNumber: 0,
      userdata: null,
      isLoaded: false,
      loginUser: null,
      page: 1,
      perPage: 25,
      totalPages: 0,
      list: [],
      sortKey: {},
      perPeges: [10, 25, 50, 75, 100],
      filterData: {
        title: "",
        accessLevelIds: [],
        categoryIds: [],
        statusIds: [],
        taggedToIds: [],
        createdByIds: [],
        createdOnRange: [],
      },
      serch_title: "",
    };
  },
  components: {
    Multiselect,
    notesFilters,
    profileHeader,
    footerView,
  },
  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.isLoaded = true;
    this.sortKey = { path: "createdOn", order: -1 };

    this.getList();
    this.sortKeys = {
      name: 1,
      statusName: 1,
      createdOn: -1,
      email: 1,
      roleName: 1,
    };
  },
  watch: {
    page: function(val) {
      this.log(val);
      this.getList();
    },
  },
};
</script>
