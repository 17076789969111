<template>
     <div class="filters_sec" :class="{'open':openFilter}">
        <button @click="toggleFilter()" class="filters_btn">Filters</button>
        <div class="filters_cnt ">
            <div class="row">
               <div v-if="userdata.roleId !=5"  class="filters_list col">
                   <h6>Created By</h6>
                      
                           <multiselect
                            v-model="selectedCreatedByList"
                                :value="'_id'"
                                :options="createdByList"
                                :searchable="true"
                                :close-on-select="false"
                                :allow-empty="true"
                                :multiple="true"
                                label="name"
                                :hide-selected="true"
                                placeholder="Select Created By"
                                track-by="name"
                                @search-change="asyncCreatedByList"
                            >
                            <template slot="selection" slot-scope="{ values, isOpen }">
                                <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }} Created By(s) selected</span
                                >
                                <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                                ></span>
                            </template>
                            <span slot="noResult">Users not found</span>
                            </multiselect>
                        

                         
                      
                        <!--<ul class="uploaded-list">
                        <li v-for="(assigned ,index) in taggedToDetails"  :key="index">
                        {{assigned.name}}
                        <span @click="removeItem(index ,taggedToDetails)">X</span>
                        </li>
                    </ul>-->

               </div>
               <div class="filters_list col">
                    <h6>Access Level</h6>
                   
                    <multiselect
                    v-model="selected_note_access_level"
                        :value="'_id'"
                        :options="note_access_level"
                        :searchable="true"
                        :close-on-select="false"
                        :allow-empty="true"
                        :hide-selected="true"
                        :multiple="true"
                        label="name"
                        placeholder="Select Access level"
                        track-by="name"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} Access lavel's selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                     <span slot="noResult">Access lavel's  not found</span>
                    </multiselect>
                </div>
               <div class="filters_list col">
                   <h6>Tagged To</h6>                                          
                        
                            <multiselect
                            v-model="taggedToDetails"
                                :value="'_id'"
                                :options="tagToList"
                                :searchable="true"
                                :close-on-select="false"
                                :allow-empty="true"
                                :multiple="true"
                                :hide-selected="true"
                                label="name"
                                placeholder="Select Tagged To"
                                track-by="name"
                                @search-change="asyncFind"
                            >
                            <template slot="selection" slot-scope="{ values, isOpen }">
                                <span
                                class="multiselect__selectcustom"
                                v-if="values.length && !isOpen"
                                >{{ values.length }}Tagged To(s) selected</span
                                >
                                <span
                                class="multiselect__selectcustom"
                                v-if="values.length && isOpen"
                                ></span>
                            </template>
                            <span slot="noResult">Users not found</span>
                            </multiselect>
                        

                         
                      
                        <!--<ul class="uploaded-list">
                        <li v-for="(assigned ,index) in taggedToDetails"  :key="index">
                        {{assigned.name}}
                        <span @click="removeItem(index ,taggedToDetails)">X</span>
                        </li>
                    </ul>-->

               </div>    
                <!--<div class="filters_list col">
                    <h6>Status</h6>
                   
                    <multiselect
                    v-model="selected_status"
                        :value="'_id'"
                        :options="user_status"
                        :searchable="true"
                        :close-on-select="false"
                        :allow-empty="true"
                        :multiple="true"
                        label="name"
                        placeholder="Select status"
                        track-by="name"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                    </multiselect>
                </div>-->

                

                 <div class="filters_list col">
                    <h6>Created Date</h6>
                    <date-range-picker  :maxDate="new Date()" :opens="'left'" :autoApply="autoApply" :ranges="false"   v-model="selected_createdDateRange"></date-range-picker>
                </div>
            </div>
            <div class="filter_actions">
                <button class="secondary_btn" @click="clearFilter()">Clear<i class="fas fa-redo"></i></button>
                <div class="d-flex">
                    <button class="filter_close secondary_btn" @click="openFilter=false">Cancel</button>
                    <button class="filter_close primary_btn" @click="applyFilters()">Apply</button>
                </div>
            </div>
        </div>
        <span class="filters_layer"  @click="toggleFilter()"></span>
    </div>
</template>
<script>
import _ from "lodash"; 
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import Multiselect from "vue-multiselect-inv";
//import { VueAutosuggest } from 'vue-autosuggest'; 

export default {
    components: {
        DateRangePicker,
        Multiselect,
       // VueAutosuggest
    },
    data() {
        return {
        // filterData: { "title": "","categoryIds": [], "statusIds": [], "taggedToIds": [], "createdByIds": [],"createdOnRange": []},
     
            user_role: [],
            user_status: [],
            note_access_level:[],//[{ item: 1, name: 'Internal' },{ item: 2, name: 'Clients' }, { item: 3, name: 'Custom' },],
            selected_status: [],
            selected_createdDateRange:{},
            autoApply: "",
            searchText:'',
            taggedToDetails:[],
            clientList:[],
            selected_note_access_level:[],
            createdByList:[],
            selectedCreatedByList:[],
            tagToList:[],
            categoryIds:[],
            selected_categoryIds:[],
        }
    },
    methods: {
        asyncFind(query){
            this.tagToList = [];
            this.searchText = query;
            this.getCliList(true)
        },
       
        asyncCreatedByList(query){
            this.createdByList = [];
            this.searchText = query;
            this.getCliList(false)
        },
        getMaterData(category){
           
            let payLoad = {
            "matcher": {
                "searchString":"",
            },
            "page": 1,
            "perpage": 10000000000,
            "category": category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
            } ;
            this.$store.dispatch("getMaterData",payLoad)
                .then((res)=>{
                // this[category] = res.list;
                 
                if(category =="user_role"){
                    //if loggied in user is super admin he can create only support team(2) AND CA admin (3)
                        if(this.userdata.roleId ==1){
                            this[category] =_.filter(res.list ,(rol)=>{
                                return rol._id ==2 || rol._id ==1
                                //return rol._id ==2 
                            });

                        }

                        //if loggied in user is CA admin he can create only Employes(4)
                        if(this.userdata.roleId ==3){
                            this[category] =_.filter(res.list ,{"_id":4 });

                        }

                }
                else if(category =="note_access_level"){
                    let dt = res.list;
                    _.forEach(dt ,(obj ,index)=>{
                     dt[index]['selected'] =false;
                     
                         if(this.userdata.roleId==5){

                             if(obj['_id'] !=3){
                                 this[category].push(dt[index]);
                             }

                         }else{
                             this[category].push(dt[index]);

                         }

                     
                    
                 });
                }
                else{
                   this[category] = res.list;  
                }
                                        
                })
                .catch((err)=>{
                    this.log(err);

                });
        },
        
        applyFilters() {
            let filterData = { "title": "","accessLevelIds":[] ,"categoryIds": [], "statusIds": [], "taggedToIds": [], "createdByIds": [],"createdOnRange": []};
            
            if((_.has(this.selected_createdDateRange ,'startDate' ) && this.selected_createdDateRange['startDate']  && this.selected_createdDateRange['startDate'] !=null ) &&
             ( _.has(this.selected_createdDateRange ,'endDate' ) && this.selected_createdDateRange['endDate']  && this.selected_createdDateRange['endDate'] !=null ) 
             
             ){   filterData['createdOnRange'].push(moment(this.selected_createdDateRange['startDate']).format("YYYY-MM-DD"));
                filterData['createdOnRange'].push(moment(this.selected_createdDateRange['endDate']).format("YYYY-MM-DD"));
            }
            //selectedCreatedByList
            if(this.selectedCreatedByList.length>0){
                _.forEach(this.selectedCreatedByList , (user)=>{
                    filterData.createdByIds.push(user._id);

                });

            }

            if(this.taggedToDetails.length>0){
                _.forEach(this.taggedToDetails , (user)=>{
                    filterData.taggedToIds.push(user._id);

                });

            }
            //selected_categoryIds
            if(this.selected_categoryIds.length>0){
                _.forEach(this.selected_categoryIds , (user)=>{
                    filterData.categoryIds.push(user._id);

                });

            }
            //selected_note_access_level
            if(this.selected_note_access_level.length>0){
                _.forEach(this.selected_note_access_level , (user)=>{
                    filterData.accessLevelIds.push(user._id);

                });

            }

            if(this.selected_status.length>0){
                _.forEach(this.selected_status ,(state)=>{
                    filterData['statusIds'].push(state['_id']);
                });
            }
            this.$emit("applyFilter" ,filterData);
            this.openFilter=false;
        },
        clearFilter(callFrom =false) {
           this.selected_createdDateRange ={startDate:null ,endDate:null};
            
            this.selected_status = [];
            this.selectedCreatedByList = [];
            this.taggedToDetails = [];
            this.selected_note_access_level =[],
            this.selected_categoryIds =[];
           if(callFrom==false){
             this.$emit("clearFilter");
             this.openFilter=false;
           }
           
        },
       ////////////////////////////////////// 
      removeItem(index ,data){
          data.splice(index,1);

      },
      getCliList(getForNotes =false) {
        let postData = {
           getMasterData: true,
           getForNotes: true,
            filters: { 
                title: this.searchText
            },
           // getAssignList: true,
           // userId: this.userId,
            sorting: {"path": "name","order": -1	},
            page: 1,
            perpage: 100,
        };
        if(getForNotes){
            postData['getForNotes'] = true
            
        }
       
        this.$store
            .dispatch("getAssignToList", postData)
            .then((response) => {
                            
                
                if(getForNotes){
                     this.tagToList =response.list;
                }else{
                    this.createdByList = response.list;
                }
            
        }).catch((err) => {
                this.log(err);
            });
      },
      
    

    },
    mounted(){
     this.getCliList(false);
      this.getMaterData("note_access_level");
      this.getMaterData("user_status");
      this.getCliList(true);
      
  },
    
}
</script>